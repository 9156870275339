export const getPublicip = async () => {
    const services = [
        { name: "ipapi.co", url: "https://ipapi.co/json/", },
        { name: "api.ipify.org", url: "https://api.ipify.org/?format=json" },
    ];
    for (const service of services) {
        try {
            const response = await fetch(service?.url);
            const ipData = await response.json();
            console.log({ service, ipData })
            return ipData?.ip;
        } catch (error) {
            console.log({ error, service })
        }
    }
    return '';
}
